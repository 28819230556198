import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PublicRoute from './Utils/PublicRoute'
import PrivateRoute from './Utils/PrivateRoute'
import EsignPublicRoute from './Utils/EsignPublicRoute'
import EsignPrivateRoute from './Utils/EsignPrivateRoute'
import BgcPublicRoute from './Utils/BgcPublicRoute'
import BgcPrivateRoute from './Utils/BgcPrivateRoute'
import AdminPublicRoute from './Utils/AdminPublicRoute'
import AdminPrivateRoute from './Utils/AdminPrivateRoute'
import { KycContext } from './Components/KycContext'
const Home = lazy(() => import('./Home'))
const Dashboard = lazy(() => import('./Dashboard/Dashboard'))
const Templates = lazy(() => import('./Templates/Templates'))
const Products = lazy(() => import('./Products/Products'))
const Drafts = lazy(() => import('./Drafts/Drafts'))
const MyOrders = lazy(() => import('./MyOrders/MyOrders'))
const APIKeys = lazy(() => import('./APIKeys/APIKeys'))
const APIUsage = lazy(() => import('./APIUsage/APIUsage'))
const APIPricing = lazy(() => import('./APIPricing/APIPricing'))
const MyAccount = lazy(() => import('./MyAccount/MyAccount'))
const WhitelistIP = lazy(() => import('./WhitelistIP/WhitelistIP'))
const TestAPIs = lazy(() => import('./TestAPIs/Sandbox'))
const OCRAPIs = lazy(() => import('./TestAPIs/OCRAPIs/OCRAPIs'))
const Logout = lazy(() => import('./Logout/Logout'))
const NotFound = lazy(() => import('./NotFound/NotFound'))
const Maintenance = lazy(() => import('./Maintenance/Maintenance'))
const CompleteKyc = lazy(() => import('./CompleteKyc/CompleteKyc'))
const Login = lazy(() => import('./Login/Login'))
const CourtCaseCheck = lazy(() => import('./Products/CourtCaseCheck/CourtCaseCheck'))
const CourtCaseResponses = lazy(() => import('./Products/CourtCaseCheck/CourtCaseResponses'))
const NormalReport = lazy(() => import('./Products/CourtCaseCheck/NormalReport/NormalReport'))
const ImmediateReport = lazy(() => import('./Products/CourtCaseCheck/ImmediateReport/ImmediateReport'))
const DelayReport = lazy(() => import('./Products/CourtCaseCheck/DelayReport/DelayReport'))
const Bgclogin = lazy(() => import('./Products/TruBGC/Login/Login'))
const BgcDashboard = lazy(() => import('./Products/TruBGC/Dashboard/Dashboard'))
const TruBGC = lazy(() => import('./Products/TruBGC/TruBGC'))
const TruBGCResponses = lazy(() => import('./Products/TruBGC/TruBGCResponses'))
const BGCDetail = lazy(() => import('./Products/TruBGC/TruBGCDetail'))
const TruBGCDownload = lazy(() => import('./Products/TruBGC/TruBGCDownload'))
const AcceptBGCRequest = lazy(() => import('./BackgroundVerification/AcceptBGCRequest'))
const RejectBGCRequest = lazy(() => import('./BackgroundVerification/RejectBGCRequest'))
const BackgroundVerification = lazy(() => import('./BackgroundVerification/BackgroundVerification'))
const BGCAadhaar = lazy(() => import('./BackgroundVerification/Components/BGCAadhaar'))
const EmployeeRating = lazy(() => import('./BackgroundVerification/EmployeeRating'))
const EmployeeReject = lazy(() => import('./BackgroundVerification/EmployeeReject'))
const Thankyou = lazy(() => import('./BackgroundVerification/Thankyou'))
const TruAML = lazy(() => import('./Products/TruAML/TruAML'))
const TruAMLResponses = lazy(() => import('./Products/TruAML/TruAMLResponses'))
const AMLDetail = lazy(() => import('./Products/TruAML/TruAMLDetail'))
const BulkPAN = lazy(() => import('./Products/BulkPAN/BulkPAN'))
const BulkPANResponses = lazy(() => import('./Products/BulkPAN/BulkPANResponses'))
const BulkPANDetail = lazy(() => import('./Products/BulkPAN/BulkPANDetail'))
const Esign = lazy(() => import('./Products/Esign/Esign'))
const EsignResponses = lazy(() => import('./Products/Esign/EsignResponses'))
const EsignDetail = lazy(() => import('./Products/Esign/EsignDetail'))
const AcceptEsign = lazy(() => import('./EsignVerification/AcceptEsign'))
const AadhaarEsign = lazy(() => import('./EsignVerification/AadhaarEsign'))
const SignDocument = lazy(() => import('./EsignVerification/SignDocument'))
const ViewDocument = lazy(() => import('./EsignVerification/ViewDocument'))
const DocumentSigned = lazy(() => import('./EsignVerification/DocumentSigned'))
const EsignVerification = lazy(() => import('./EsignVerification/EsignVerification'))
const AppendSignature = lazy(() => import('./EsignVerification/AppendSignature'))
const Esignlogin = lazy(() => import('./Products/Esign/Login/Login'))
const EsignDashboard = lazy(() => import('./Products/Esign/Dashboard/Dashboard'))
const EsignResponsesData = lazy(() => import('./Products/Esign/EsignResponsesData'))
const TruBank = lazy(() => import('./Products/TruBank/TruBank'))
const TruBankResponses = lazy(() => import('./Products/TruBank/TruBankResponses'))
const TruBankDetail = lazy(() => import('./Products/TruBank/TruBankDetail'))
const TruBankReport = lazy(() => import('./Products/TruBank/TrubankReport'))
const AdminLogin = lazy(() => import('./Admin/Login/Login'))
const AdminDashboard = lazy(() => import('./Admin/Dashboard/Dashboard'))
const UserAccounts = lazy(() => import('./Admin/UserAccounts/UserAccounts'))
const UserDetail = lazy(() => import('./Admin/UserAccounts/UserDetail'))
const AdminTestAPIs = lazy(() => import('./Admin/TestAPIs/TestAPIs'))
const AdminAPIs = lazy(() => import('./Admin/APIs/APIs'))
const OverallTransactions = lazy(() => import('./Admin/OverallTransactions/OverallTransactions'))
const IPHits = lazy(() => import('./Admin/IPHits/IPHits'))
const BulkHit = lazy(() => import('./Admin/BulkHit/BulkHit'))
const AdminWhitelistIP = lazy(() => import('./Admin/WhitelistIP/WhitelistIP'))
const SwitchSource = lazy(() => import('./Admin/SwitchSource/SwitchSource'))
const BGCUsers = lazy(() => import('./Admin/BGCUsers/BGCUsers'))
const BGCUsersDetails = lazy(() => import('./Admin/BGCUsers/BGCUsersDetail'))
const WhitelabelUsers = lazy(() => import('./Admin/WhitelabelUsers/WhitelabelUsers'))
const ClientDetails = lazy(() => import('./Admin/WhitelabelUsers/ClientDetails/ClientDetails'))
const UserDetails = lazy(() => import('./Admin/WhitelabelUsers/UserDetails/UserDetails'))
const AdminTruBank = lazy(() => import('./Admin/TruBank/TruBank'))
const Postpaid = lazy(() => import('./Admin/Postpaid/Postpaid'))
const PostpaidUserDetail = lazy(() => import('./Admin/Postpaid/PostpaidUserDetail'))
const AdminLogout = lazy(() => import('./Admin/Logout/Logout'))
const APILogs = lazy(() => import('./Admin/APILogs/APILogs'))
const InvoiceTransactions = lazy(() => import('./Admin/InvoiceTransactions/InvoiceTransactions'))
const APISettings = lazy(() => import('./Admin/APISettings/APISettings'))
const DeleteSubdomain = lazy(() => import('./Admin/DeleteSubdomain/DeleteSubdomain'))
const AdminBatchProcessing = lazy(() => import('./Admin/BatchProcessing/BatchProcessing'))
const AdminOCRAPIs = lazy(() => import('./Admin/OCRAPIs/OCRAPIs'))
const TruBGV = lazy(() => import('./Products/TruBGV/TruBGV'))
const TruBGVVerification = lazy(() => import('./Products/TruBGV/TruBGVVerification'))
const TruBGVResponses = lazy(() => import('./Products/TruBGV/TruBGVResponses'))
const TruBGVDetail = lazy(() => import('./Products/TruBGV/TruBGVDetail'))
const TruBGVDownload = lazy(() => import('./Products/TruBGV/TruBGVDownload'))
const VerifyUserMail = lazy(() => import('./MyAccount/VerifyUserMail'))
const UnSubscribe = lazy(() => import('./Unsubscribe/UnSubscribe'))
const BulkHitUser = lazy(() => import('./Products/BulkHit/BulkHit'))
const BulkHitResponse = lazy(() => import('./Products/BulkHit/BulkHitResponse'))
const BatchProcessing = lazy(() => import('./Products/BatchProcessing/BatchProcessing'))
const BatchProcessingResponses = lazy(() => import('./Products/BatchProcessing/BatchProcessingResponses'))
const ForgotPasswordMailVerify = lazy(() => import('./Components/LoginModal/Components/ForgotPasswordMailVerify'))
const VideoTutorials = lazy(() => import('./VideoTutorials/VideoTutorials'))
const OnboardingSolution = lazy(() => import('./OnboardingSolution/OnboardingSolution'))
const OnboardingSolutionFlows = lazy(() => import('./OnboardingSolution/OnboardingSolutionFlows'))
const OnboardingSolutionResponses = lazy(() => import('./OnboardingSolution/OnboardingSolutionResponses'))
const OnboardingSolutionDetail = lazy(() => import('./OnboardingSolution/OnboardingSolutionDetail'))
const OnboardingSolutionDownload = lazy(() => import('./OnboardingSolution/OnboardingSolutionsDownload'))
const OnboardingSolutionSettings = lazy(() => import('./OnboardingSolution/OnboardingSolutionSettings'))
const VirtualTryOn = lazy(() => import('./VirtualTryOn/VirtualTryOn'))
const GlobalOCR = lazy(() => import('./GlobalOCR/GlobalOCR'))
const VirtualTryOnAd = lazy(() => import('./VirtualTryOnAd/VirtualTryOnAd'))
const Advertisement = lazy(() => import('./Advertisement/Advertisement'))
const GlobalKYCSuiteReport = lazy(() => import('./GlobalOCR/Components/GlobalKYCDownload'))
const GlobalOcrResponses = lazy(() => import('./GlobalOCR/Components/GlobalOcrResponses'))
const VideoKYC = lazy(() => import('./VideoKYC/VideoKYC'))
const VideoKYCReport = lazy(() => import('./VideoKYC/Components/Report'))
const VideoKYCSettings = lazy(() => import('./VideoKYC/Components/VideoKYCSettings'))
const VideoKycSavedFlows = lazy(() => import('./VideoKYC/Components/SavedFlows'))
const VideoKycReportDetails = lazy(() => import('./VideoKYC/Components/ReportDetails'))
const Reports = lazy(() => import('./OnboardingSolution/Verifications/VehicleIntelligenceSystemReport'))
const APITrackingDownload = lazy(() => import('./APIUsage/Components/APITrackingDownload'))
const ReportOutline = lazy(() => import('./VideoKYC/Components/ReportOutline'))
const VaahanAnalytics = lazy(()=> import('./Products/VaahanAnalytics/VaahanAnalytics'))

export default function App() {
    const { individualStep, companyStep } = useContext(KycContext)
    const [kycComplete, setKycComplete] = useState(false)

    useEffect(() => {
        if (individualStep === 'kyc-complete' || companyStep === 'kyc-complete') {
            setKycComplete(true)
        }
    }, [individualStep, companyStep])

    return (
        <BrowserRouter>
            <div className="App">
                <Suspense
                    fallback={
                        <div className="loaderCntMain">
                            <div className="loaderCnt">
                                <div className="leftDiv"></div>
                                <div className="rightDiv">
                                    <div className="rightHeader">
                                        <div className="headerContent large"></div>
                                        <div className="headerContent large"></div>
                                        <div className="headerContent large"></div>
                                        <div className="headerContent small"></div>
                                    </div>
                                    <div className="rightMain">
                                        <div className="rightUpper"></div>
                                        <div className="rightLower"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                >
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <PublicRoute path="/app-login" component={Login} />
                        <PublicRoute path="/login" component={Login} />
                        <Route path="/dashboard" component={Dashboard} />
                        <Route path="/templates" component={Templates} />
                        <Route path="/products" component={Products} />
                        <Route path="/drafts" component={Drafts} />
                        <Route path="/my-orders" component={MyOrders} />
                        <Route path="/api-keys" component={kycComplete ? APIKeys : CompleteKyc} />
                        <Route path="/api-usage" component={kycComplete ? APIUsage : CompleteKyc} />
                        <Route path="/api-tracking-report" component={APITrackingDownload} />
                        <Route path="/api-pricing" component={APIPricing} />
                        <Route path="/my-account" component={MyAccount} />
                        <Route path="/subusers-verify-email/:token" component={VerifyUserMail} />
                        <Route path="/whitelist-ip" component={WhitelistIP} />
                        <Route path="/video-tutorials" component={VideoTutorials} />
                        <Route path="/test-apis" component={kycComplete ? TestAPIs : CompleteKyc} />
                        <Route path="/ocr-apis" component={OCRAPIs} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/reset-password-user/:token" component={ForgotPasswordMailVerify} />
                        <Route path="/virtual-try-on-testing-url" component={VirtualTryOn} />
                        <Route path="/virtual-try-on" component={VirtualTryOnAd} />
                        <Route path="/advertisement" component={Advertisement} />
                        <Route path="/pdf-result" component={Reports} />
                        {/* Court case */}
                        <PrivateRoute path="/court-case-check" component={kycComplete ? CourtCaseCheck : CompleteKyc} />
                        <PrivateRoute
                            path="/court-case-responses"
                            component={kycComplete ? CourtCaseResponses : CompleteKyc}
                        />
                        <PrivateRoute path="/normal-report" component={kycComplete ? NormalReport : CompleteKyc} />
                        <PrivateRoute
                            path="/immediate-report"
                            component={kycComplete ? ImmediateReport : CompleteKyc}
                        />
                        <PrivateRoute path="/delay-report" component={kycComplete ? DelayReport : CompleteKyc} />
                        {/* TruBGC */}
                        <BgcPublicRoute path="/bgc/login" component={Bgclogin} />
                        <BgcPrivateRoute path="/bgc-dashboard" component={kycComplete ? BgcDashboard : CompleteKyc} />
                        <BgcPrivateRoute path="/bgc" component={kycComplete ? TruBGC : CompleteKyc} />
                        <BgcPrivateRoute
                            path="/bgc-responses"
                            component={kycComplete ? TruBGCResponses : CompleteKyc}
                        />
                        <BgcPrivateRoute path="/bgc-detail" component={kycComplete ? BGCDetail : CompleteKyc} />
                        <Route path="/bgc-report/:id" component={TruBGCDownload} />
                        <Route path="/accept-bgc-request/:code" component={AcceptBGCRequest} />
                        <Route path="/reject-bgc-request/:code" component={RejectBGCRequest} />
                        <Route path="/background-verification/:step" component={BackgroundVerification} />
                        <Route path="/bgc-aadhaar/:code" component={BGCAadhaar} />
                        <Route path="/employee-rating/:encryptedUrl" component={EmployeeRating} />
                        <Route path="/employee-reject/:encryptedUrl" component={EmployeeReject} />
                        <Route path="/thankyou" component={Thankyou} />
                        {/* TruAML */}
                        <PrivateRoute path="/aml" component={kycComplete ? TruAML : CompleteKyc} />
                        <PrivateRoute path="/aml-responses" component={kycComplete ? TruAMLResponses : CompleteKyc} />
                        <PrivateRoute path="/aml-detail" component={kycComplete ? AMLDetail : CompleteKyc} />
                        {/* Bulk PAN */}
                        <PrivateRoute path="/bulk-pan" component={kycComplete ? BulkPAN : CompleteKyc} />
                        <PrivateRoute
                            path="/bulk-pan-responses"
                            component={kycComplete ? BulkPANResponses : CompleteKyc}
                        />
                        <PrivateRoute path="/bulk-pan-detail" component={kycComplete ? BulkPANDetail : CompleteKyc} />
                        {/* Bulk Hit */}
                        <PrivateRoute path="/bulk-hit" component={kycComplete ? BulkHitUser : CompleteKyc} />
                        <PrivateRoute
                            path="/bulk-hit-responses"
                            component={kycComplete ? BulkHitResponse : CompleteKyc}
                        />
                        {/* Request Bulk Hit */}
                        <PrivateRoute
                            path="/batch-processing"
                            component={kycComplete ? BatchProcessing : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/batch-processing-responses"
                            component={kycComplete ? BatchProcessingResponses : CompleteKyc}
                        />
                        {/* <PrivateRoute path='/bulk-pan-detail' component={BulkPANDetail} /> */}
                        {/* TruSign */}
                        <EsignPublicRoute path="/esign/login" component={Esignlogin} />
                        <EsignPrivateRoute path="/esign" component={kycComplete ? Esign : CompleteKyc} />
                        <EsignPrivateRoute
                            path="/esign-dashboard"
                            component={kycComplete ? EsignDashboard : CompleteKyc}
                        />
                        <EsignPrivateRoute
                            path="/esign-responses"
                            component={kycComplete ? EsignResponses : CompleteKyc}
                        />
                        <EsignPrivateRoute path="/esign-detail" component={kycComplete ? EsignDetail : CompleteKyc} />
                        <Route path="/accept-esign/:code" component={AcceptEsign} />
                        <Route path="/esign-response/:code" component={EsignResponsesData} />
                        <Route path="/aadhaar-esign/:code" component={AadhaarEsign} />
                        <Route path="/sign-document/:code" component={SignDocument} />
                        <Route path="/view-document/:code" component={ViewDocument} />
                        <Route path="/document-signed" component={DocumentSigned} />
                        <Route path="/esign-verification" component={EsignVerification} />
                        <Route path="/append-signature" component={AppendSignature} />
                        {/* TruBank */}
                        <PrivateRoute path="/trubank" component={kycComplete ? TruBank : CompleteKyc} />
                        <PrivateRoute
                            path="/trubank-responses"
                            component={kycComplete ? TruBankResponses : CompleteKyc}
                        />
                        <PrivateRoute path="/trubank-detail" component={kycComplete ? TruBankDetail : CompleteKyc} />
                        <PrivateRoute
                            path="/trubank-report/:id"
                            component={kycComplete ? TruBankReport : CompleteKyc}
                        />
                        {/* TruBGV */}
                        <PrivateRoute path="/trubgv" component={kycComplete ? TruBGV : CompleteKyc} />
                        <PrivateRoute
                            path="/trubgv-verification"
                            component={kycComplete ? TruBGVVerification : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/trubgv-responses"
                            component={kycComplete ? TruBGVResponses : CompleteKyc}
                        />
                        <PrivateRoute path="/trubgv-detail" component={kycComplete ? TruBGVDetail : CompleteKyc} />
                        <PrivateRoute path="/trubgv-download" component={kycComplete ? TruBGVDownload : CompleteKyc} />
                        {/* Onboarding solution pages */}
                        <PrivateRoute
                            path="/onboarding-solution"
                            component={kycComplete ? OnboardingSolution : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/onboarding-solution-flows"
                            component={kycComplete ? OnboardingSolutionFlows : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/onboarding-solution-responses"
                            component={kycComplete ? OnboardingSolutionResponses : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/onboarding-solution-detail"
                            component={kycComplete ? OnboardingSolutionDetail : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/onboarding-solution-download"
                            component={kycComplete ? OnboardingSolutionDownload : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/onboarding-solution-settings"
                            component={kycComplete ? OnboardingSolutionSettings : CompleteKyc}
                        />
                        {/* Global OCR pages */}
                        <PrivateRoute path="/global-ocr" component={kycComplete ? GlobalOCR : CompleteKyc} />
                        <PrivateRoute
                            path="/global-ocr-report/:id"
                            component={kycComplete ? GlobalKYCSuiteReport : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/global-ocr-responses"
                            component={kycComplete ? GlobalOcrResponses : CompleteKyc}
                        />
                        {/* Video KYC pages */}
                        <PrivateRoute path="/video-kyc" component={kycComplete ? VideoKYC : CompleteKyc} />
                        <PrivateRoute
                            path="/video-kyc-reports"
                            component={kycComplete ? VideoKYCReport : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/video-kyc-settings"
                            component={kycComplete ? VideoKYCSettings : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/video-kyc-templates"
                            component={kycComplete ? VideoKycSavedFlows : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/video-kyc-details"
                            component={kycComplete ? VideoKycReportDetails : CompleteKyc}
                        />
                        <PrivateRoute
                            path="/video-kyc-report-download"
                            component={kycComplete ? ReportOutline : CompleteKyc}
                        />
                        {/* Vaahan Analytics */}
                        <PrivateRoute path="/vaahan-analytics" component={VaahanAnalytics} />

                        {/* Admin pages */}
                        <AdminPublicRoute path="/admin/login" component={AdminLogin} />
                        <AdminPrivateRoute path="/admin/dashboard" component={AdminDashboard} />
                        <AdminPrivateRoute path="/admin/user-accounts" component={UserAccounts} />
                        <AdminPrivateRoute path="/admin/user-detail" component={UserDetail} />
                        <AdminPrivateRoute path="/admin/test-apis" component={AdminTestAPIs} />
                        <AdminPrivateRoute path="/admin/apis" component={AdminAPIs} />
                        <AdminPrivateRoute path="/admin/overall-transactions" component={OverallTransactions} />
                        <AdminPrivateRoute path="/admin/ip-hits" component={IPHits} />
                        <AdminPrivateRoute path="/admin/bulk-hit" component={BulkHit} />
                        <AdminPrivateRoute path="/admin/whitelist-ip" component={AdminWhitelistIP} />
                        <AdminPrivateRoute path="/admin/switch-source" component={SwitchSource} />
                        <AdminPrivateRoute path="/admin/bgc-users" component={BGCUsers} />
                        <AdminPrivateRoute path="/admin/bgc-users-detail" component={BGCUsersDetails} />
                        <AdminPrivateRoute path="/admin/whitelabel-users" component={WhitelabelUsers} />
                        <AdminPrivateRoute path="/admin/client-details" component={ClientDetails} />
                        <AdminPrivateRoute path="/admin/user-details" component={UserDetails} />
                        <AdminPrivateRoute path="/admin/trubank" component={AdminTruBank} />
                        <AdminPrivateRoute path="/admin/postpaid" component={Postpaid} />
                        <AdminPrivateRoute path="/admin/postpaid-user-detail" component={PostpaidUserDetail} />
                        <AdminPrivateRoute path="/admin/logout" component={AdminLogout} />
                        <AdminPrivateRoute path="/admin/api-logs" component={APILogs} />
                        <AdminPrivateRoute path="/admin/ocr-apis" component={AdminOCRAPIs} />
                        <AdminPrivateRoute path="/admin/invoice-transactions" component={InvoiceTransactions} />
                        <AdminPrivateRoute path="/admin/api-settings" component={APISettings} />
                        <AdminPrivateRoute path="/admin/delete-subdomain" component={DeleteSubdomain} />
                        <AdminPrivateRoute path="/admin/batch-processing" component={AdminBatchProcessing} />

                        {/* Maintenance */}
                        <Route path="/maintenance" component={Maintenance} />
                        {/* Unsubscribe E-Mail  */}
                        <Route path="/unsubscribe/:code" component={UnSubscribe} />
                        {/* Page not found */}
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
            </div>
        </BrowserRouter>
    )
}
